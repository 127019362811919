/* eslint-disable no-unused-expressions */
import { required, maxLength } from 'vuelidate/lib/validators';

const infoValidationMixin = {
  validations: {
    carInfo: {
      year: {
        required,
      },
      brand: {
        required,
      },
      model: {
        required,
      },
      bodyType: {
        required,
      },
      transmissionType: {
        required,
      },
      fuelType: {
        required,
      },
      version: {
        required,
      },
      kilometers: {
        required,
      },
      exchangeDescription: {
        maxLength: maxLength(500),
      },
    },
  },
  computed: {
    name() {
      return this.data;
    },
  },
  methods: {
    exchangeDescriptions() {
      const errors = [];
      const { $dirty, maxLength, $params } = this.$v.carInfo.exchangeDescription;
      if (!$dirty) return errors;
      !maxLength && errors.push(`Notunuz maks ${$params.maxLength.max} karakter olmalıdır.`);
      return errors;
    },
    modelErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.model;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir model seçin.`);
      return errors;
    },
    bodyTypeErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.bodyType;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir gövde tipi seçin.`);
      return errors;
    },
    transmissionTypeErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.transmissionType;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir vites tipi seçin.`);
      return errors;
    },
    fuelTypeErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.fuelType;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir yakıt tipi seçin.`);
      return errors;
    },
    versionErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.version;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir versiyon seçin.`);
      return errors;
    },
    kilometersErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.kilometers;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen aracınızın kilometresini girin.`);
      return errors;
    },
    colorErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.carInfo.color;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen renk seçin.`);
      return errors;
    },
  },
};

export default infoValidationMixin;
