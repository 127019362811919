<template>
  <v-card color="transparent" flat v-if="getWizardStep === wizardStep.CAR_INFO">
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-subheader>Model Yılı *</v-subheader>
          <v-select
            label="Year"
            solo
            item-text="value"
            item-value="id"
            hide-details
            flat
            dense
            class="rounded-lg"
            v-model="$v.carInfo.year.$model"
            :items="getYears"
            @change="yearChanged"
          >
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-subheader>Marka *</v-subheader>
          <v-select
            label="Brand"
            solo
            item-text="value"
            item-value="id"
            hide-details
            flat
            dense
            class="rounded-lg"
            v-model="$v.carInfo.brand.$model"
            :items="getBrands"
            :returnObject="true"
            :disabled="brandIsDisabled"
            @change="brandChanged"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-subheader>Model *</v-subheader>
          <v-select
            label="Model"
            solo
            item-text="value"
            item-value="id"
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :items="getModels"
            :returnObject="true"
            :disabled="modelIsDisabled"
            :error-messages="modelErrors()"
            v-model="$v.carInfo.model.$model"
            @change="modelChanged"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-subheader>Kasa Tipi *</v-subheader>
          <v-select
            label="Kasa Tipi *"
            solo
            item-text="value"
            item-value="id"
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :items="getBodyTypes"
            :returnObject="true"
            :disabled="bodyTypeIsDisabled"
            :error-messages="bodyTypeErrors()"
            v-model="$v.carInfo.bodyType.$model"
            @change="bodyTypeChanged"
          >
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-subheader>Vites *</v-subheader>
          <v-select
            label="Vites"
            solo
            item-text="value"
            item-value="id"
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :items="getTransmissionTypes"
            :returnObject="true"
            :disabled="transmissionTypeIsDisabled"
            :error-messages="transmissionTypeErrors()"
            v-model="$v.carInfo.transmissionType.$model"
            @change="transmissionTypeChanged"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-subheader>Yakıt *</v-subheader>
          <v-select
            label="Yakıt"
            solo
            item-text="value"
            item-value="id"
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :items="getFuelTypes"
            :returnObject="true"
            :disabled="fuelTypeIsDisabled"
            :error-messages="fuelTypeErrors()"
            v-model="$v.carInfo.fuelType.$model"
            @change="fuelTypeChanged"
          >
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-subheader>Versiyon *</v-subheader>
          <v-select
            label="Versiyon"
            solo
            item-text="value"
            item-value="id"
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :items="getVersions"
            :returnObject="true"
            :disabled="versionIsDisabled"
            :error-messages="versionErrors()"
            v-model="$v.carInfo.version.$model"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-subheader>Kilometre *</v-subheader>
          <v-text-field
            inputmode="numeric"
            label="Kilometre"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :error-messages="kilometersErrors()"
            v-model="$v.carInfo.kilometers.$model"
            @keyup="kilometersKeyPress"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-subheader class="leadgen-combobox-title">Renk</v-subheader>
          <v-select
            label="Renk"
            solo
            item-text="value"
            item-value="id"
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :items="getColors"
            :returnObject="true"
            v-model="carInfo.color"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn
          block
          :loading="loading"
          shaped
          color="primary"
          class="px-5 rounded-lg text-capitalize"
          v-text="'Devam Et'"
          @click="continueProcess"
        ></v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import wizardMixin from '@/mixins/wizardMixin';
import { wizardStep } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import {
  CHANGE_WIZARD_STEP,
  GET_WIZARD_YEARS,
  GET_WIZARD_DATA,
  SET_CAR_WIZARD,
  CREATE_CAR_WIZARD,
  FETCH_COLORS,
} from '@/store/modules/carWizard.module';
import { validationMixin } from 'vuelidate';
import infoValidationMixin from '@/mixins/validations/carWizard/infoValidationMixin';

export default {
  name: 'Info',
  mixins: [validationMixin, infoValidationMixin, wizardMixin],
  data() {
    return {
      carInfo: {
        year: null,
        brand: null,
        model: null,
        bodyType: null,
        transmissionType: null,
        fuelType: null,
        version: null,
        kilometers: null,
        color: null,
      },
      nf: new Intl.NumberFormat('tr-TR'),
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'getYears',
      'getBrands',
      'getModels',
      'getBodyTypes',
      'getTransmissionTypes',
      'getFuelTypes',
      'getVersions',
      'getColors',
      'getWizardCarInfo',
    ]),
    brandIsDisabled() {
      return this.getBrands.length === 0;
    },
    modelIsDisabled() {
      return this.getModels.length === 0;
    },
    bodyTypeIsDisabled() {
      return this.getBodyTypes.length === 0;
    },
    transmissionTypeIsDisabled() {
      return this.getTransmissionTypes.length === 0;
    },
    fuelTypeIsDisabled() {
      return this.getFuelTypes.length === 0;
    },
    versionIsDisabled() {
      return this.getVersions.length === 0;
    },
    isExchangeRequest() {
      return this.carInfo.isExchangeRequest;
    },
  },
  created() {
    this.$nextTick(() => {
      this.changeWizardStep(this.wizardStep.CAR_INFO);

      if (this.getBrands.length === 0) this.getWizardYears();
      if (this.getColors.length === 0) this.fetchColors();

      this.carInfo = {
        year: this.getWizardCarInfo.year,
        brand: this.getWizardCarInfo.brand,
        model: this.getWizardCarInfo.model,
        bodyType: this.getWizardCarInfo.bodyType,
        transmissionType: this.getWizardCarInfo.transmissionType,
        fuelType: this.getWizardCarInfo.fuelType,
        version: this.getWizardCarInfo.version,
        kilometers: this.getWizardCarInfo.kilometers,
        color: this.getWizardCarInfo.color,
        isExchangeRequest: this.getWizardCarInfo.isExchangeRequest,
        exchangeDescription: this.getWizardCarInfo.exchangeDescription,
      };
    });
  },
  methods: {
    ...mapActions({
      changeWizardStep: CHANGE_WIZARD_STEP,
      getWizardYears: GET_WIZARD_YEARS,
      setCarWizard: SET_CAR_WIZARD,
      createCarWizard: CREATE_CAR_WIZARD,
      getWizardData: GET_WIZARD_DATA,
      fetchColors: FETCH_COLORS,
    }),
    yearChanged(year) {
      const payload = {
        year,
      };
      this.getWizardData({ payload, fillArrayField: 'brands' }).then(() => {
        this.brandChanged();
      });
    },
    brandChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand?.id,
      };

      this.getWizardData({ payload, fillArrayField: 'models' }).then(() => {
        if (!this.getModels.some((x) => x.id === this.carInfo.model?.id)) {
          this.carInfo.model = null;
          return;
        }

        this.modelChanged();
      });
    },
    modelChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand?.id,
        modelId: this.carInfo.model?.id,
      };
      this.getWizardData({ payload, fillArrayField: 'bodyTypes' }).then(() => {
        if (!this.getBodyTypes.some((x) => x.id === this.carInfo.bodyType?.id)) {
          if (this.getBodyTypes.length === 1) {
            this.carInfo.bodyType = this.getBodyTypes[0];
          } else {
            this.carInfo.bodyType = null;
            return;
          }
        }

        this.bodyTypeChanged();
      });
    },
    bodyTypeChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand.id,
        modelId: this.carInfo.model.id,
        bodyTypeId: this.carInfo.bodyType.id,
      };
      this.getWizardData({ payload, fillArrayField: 'transmissionTypes' }).then(() => {
        if (!this.getTransmissionTypes.some((x) => x.id === this.carInfo.transmissionType?.id)) {
          if (this.getTransmissionTypes.length === 1)
            // eslint-disable-next-line prefer-destructuring
            this.carInfo.transmissionType = this.getTransmissionTypes[0];
          else {
            this.carInfo.transmissionType = null;
            return;
          }
        }

        this.transmissionTypeChanged();
      });
    },
    transmissionTypeChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand.id,
        modelId: this.carInfo.model.id,
        bodyTypeId: this.carInfo.bodyType.id,
        transmissionTypeId: this.carInfo.transmissionType.id,
      };
      this.getWizardData({ payload, fillArrayField: 'fuelTypes' }).then(() => {
        if (!this.getFuelTypes.some((x) => x.id === this.carInfo.fuelType?.id)) {
          if (this.getFuelTypes.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.carInfo.fuelType = this.getFuelTypes[0];
          } else {
            this.carInfo.fuelType = null;
            return;
          }
        }

        this.fuelTypeChanged();
      });
    },
    fuelTypeChanged() {
      const payload = {
        year: this.carInfo.year,
        brandId: this.carInfo.brand.id,
        modelId: this.carInfo.model.id,
        bodyTypeId: this.carInfo.bodyType.id,
        transmissionTypeId: this.carInfo.transmissionType.id,
        fuelTypeId: this.carInfo.fuelType.id,
      };
      this.getWizardData({ payload, fillArrayField: 'versions' }).then(() => {
        if (!this.getVersions.some((x) => x.id === this.carInfo.version?.id)) {
          if (this.getVersions.length === 1) {
            this.carInfo.version = this.getVersions[0];
          } else {
            this.carInfo.version = null;
          }
        }
      });
    },
    kilometersKeyPress($event) {
      const text = $event.target.value.replace(/[^0-9]/g, '');
      this.carInfo.kilometers = this.nf.format(text);
      return true;
    },
    continueProcess() {
      this.$v.$touch();
      if (this.$v.carInfo.$invalid) {
        return;
      }

      const { carInfo } = this;
      if (carInfo.kilometers !== null && this.carInfo.kilometers !== '')
        carInfo.kilometers = carInfo.kilometers.replace(/[^0-9]/g, '');

      this.loading = true;
      this.createCarWizard({
        carInfo: { ...this.carInfo },
        step: wizardStep.DAMAGE,
      })
        .then(() => {
          this.$router.push({ name: 'wizardDamage' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    isExchangeRequest: {
      handler(active) {
        if (!active) {
          this.carInfo.exchangeDescription = null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
